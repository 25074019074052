var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"overline justify-center"},[_vm._v("Carrito de compras")]),(_vm.shoppingCart.length)?_c('div',[_c('v-card-text',{staticClass:"shopping-cart-container"},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.shoppingCart),function(item){return _c('v-list-item',{key:item.id},[_c('v-list-item-action',[_c('v-text-field',{staticClass:"centered-input",attrs:{"dense":"","outlined":"","hide-details":true},model:{value:(item.amount),callback:function ($$v) {_vm.$set(item, "amount", $$v)},expression:"item.amount"}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}}),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.presentation)+" ")]),_c('v-divider')],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.removeItemFromCart(item)}}},[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-close')}})],1),_c('v-list-item-action-text',{domProps:{"textContent":_vm._s(
                  '$ ' +
                  item.price +
                  ' x ' +
                  item.amount +
                  (item.amount > 1 ? ' unidades' : ' unidad')
                )}})],1)],1)}),1)],1)],1):_c('v-card-text',[_c('span',[_vm._v(" El carrito está vacío")])]),_c('v-divider'),_c('v-card-text',[_c('div',[_c('p',{staticClass:"text-sm-right",domProps:{"textContent":_vm._s('Total: $ ' + _vm.total)}})])]),_c('v-divider'),(_vm.shoppingCart.length)?_c('v-card-actions',[_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){return _vm.cleanShoppingCart()}}},[_c('v-icon',[_vm._v("mdi-cart-off")]),_vm._v(" Vaciar carrito ")],1),_c('v-spacer'),_c('v-btn',{staticClass:"pa-5",attrs:{"loading":_vm.savingOrder,"color":"teal","dark":"","rounded":""},on:{"click":function($event){return _vm.saveSC()}}},[_c('v-icon',[_vm._v("mdi-cart")]),_vm._v(" Realizar pedido ")],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }