<template>
  <div>
    <v-card-title>
      <v-chip color="teal lighten-1" outlined dark label>
        <span class="overline">Venta directa </span>
      </v-chip>
      <v-spacer />
    </v-card-title>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th v-for="item in propeties" :key="item.key">
              {{ item.title }}
            </th>
            <th class="text-center">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" dark v-bind="attrs" v-on="on">
                    mdi-help-circle
                  </v-icon>
                </template>
                <v-list color="transparent">
                  <v-list-item v-for="cart in carts" :key="cart.id">
                    <v-list-item-icon
                      ><v-icon :color="cart.color">mdi-circle</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="white--text" v-text="cart.text" />
                  </v-list-item>
                </v-list>
              </v-tooltip>
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-if="!loading">
            <template v-if="pages.length">
              <tr v-for="(row, index) in pages[currentPage - 1]" :key="index">
                <td v-for="item in propeties" :key="item.key">
                  {{ item.prepend }}
                  {{
                    item.key === "price" ? fix(row[item.key]) : row[item.key]
                  }}
                  {{ item.append }}
                </td>
                <td class="text-center">
                  <v-btn
                    depressed
                    :color="shoppingCartClass(row.stock)"
                    :dark="!row.stock == 0"
                    :disabled="row.stock == 0"
                    icon
                    width="20px"
                    @click="$emit('addToCart', row)"
                  >
                    <v-icon>mdi-cart-arrow-down</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </template>
          <template v-else>
            <tr>
              <td v-for="item in propeties" :key="item.key">
                <v-skeleton-loader type="table-cell@5" />
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-simple-table>
    <div class="text-center">
      <v-pagination
        v-model="currentPage"
        :length="pages.length"
        color="teal"
        circle
        :disabled="loading || !pages"
        total-visible="5"
      ></v-pagination>
    </div>

    <template v-if="!loading && !items.length">
      <v-container>
        <v-row justify="center">
          <v-col cols="12">
            <v-alert
              color="red ligthen-2 ma-5"
              border="left"
              dark
              type="error"
              class="rounded20"
            >
              No se han encontrado resultados
            </v-alert>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>
import { paginateArray } from "../../services/utils";
import {
  filterDirectSale,
  getShoppingCartClass,
} from "../../services/directSaleUtils.js";

export default {
  name: "DirectSaleTable",
  props: ["items", "loading"],
  data: () => ({
    propeties: [
      {
        key: "name",
        title: "Nombre",
      },
      {
        key: "drug",
        title: "Droga",
      },
      {
        key: "presentation",
        title: "Presentación",
      },
      {
        key: "laboratory",
        title: "Laboratorio",
      },
      {
        key: "discount",
        title: "Descuento",
      },
      {
        key: "price",
        title: "Precio",
        prepend: "$",
      },
      {
        key: "iva",
        title: "Grav.",
      },
    ],
    carts: [
      {
        key: 1,
        color: "grey",
        text: "Sin stock",
      },
      {
        key: 2,
        color: "red",
        text: "Stock critico",
      },
      {
        key: 3,
        color: "amber",
        text: "Stock moderado",
      },
      {
        key: 4,
        color: "teal",
        text: "Stock alto",
      },
    ],
    currentPage: 1,
  }),
  computed: {
    pages: function () {
      return paginateArray(
        this.items.filter((i) => i != null),
        15
      );
    },
  },
  methods: {
    shoppingCartClass(stock) {
      return getShoppingCartClass(stock);
    },
    fix(n) {
      return n.toFixed(2);
    },
  },
};
</script>
