<template>
  <div>
    <v-row>
      <v-col sm="12" md="12" xl="3">
        <MedBrowser @search="filter = $event" />
        <v-sheet class="pa-10" style="border-radius: 20px">
          <v-tabs v-model="tab" centered>
            <v-tabs-slider color="teaL"></v-tabs-slider>
            <v-tab disabled key="orders"> Mis pedidos </v-tab>
            <v-tab key="order"> Pedido en gestión </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item key="orders">
              <v-card flat>
                <ShoppingCard :orders="orders" :loading="ordersLoading" />
              </v-card>
            </v-tab-item>
            <v-tab-item key="order">
              <v-card flat>
                <ShoppingCartCard />
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-sheet>
      </v-col>
      <v-col sm="12" md="12" xl="9">
        <v-sheet class="pa-10 rounded20">
          <DirectSaleTable
            :items="saleItems"
            :loading="loading"
            @addToCart="add($event)"
          />
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import DirectSaleTable from "../../components/sales/DirectSaleTableComponent.vue";
import MedBrowser from "../../components/sales/MedBrowserComponent.vue";
import ShoppingCard from "../../components/sales/ShoppingCardComponent.vue";
import ShoppingCartCard from "../../components/shoppingCart/ShoppingCartCardComponent.vue";
import { filterDirectSale, normalize } from "../../services/directSaleUtils.js";

export default {
  name: "Sales",
  components: {
    DirectSaleTable,
    ShoppingCard,
    ShoppingCartCard,
    MedBrowser,
  },

  data: () => ({
    filter: {
      word: "",
      stock: true, // Por defecto, sólo stock disponible
    },
    tab: 1,
  }),

  mounted() {
    this.getDirectSale();
    this.getOrders();
  },
  computed: {
    ...mapGetters("directSale", [
      "directSale",
      "loading",
      "orders",
      "ordersLoading",
    ]),
    saleItems: function () {
      return normalize(filterDirectSale(this.directSale, this.filter));
    },
  },
  methods: {
    ...mapActions("directSale", ["getDirectSale", "getOrders"]),
    ...mapActions("shoppingCart", ["addToCart"]),

    add(item) {
      this.tab = 1;
      this.addToCart(item);
    },
  },
};
</script>

<style>
</style>
