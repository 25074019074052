<template>
  <v-dialog
    v-model="show"
    width="50vw"
    persistent
    v-on:click:outside="$emit('close')"
    scrollable
  >
    <v-card class="mx-auto">
      <v-img
        class="white--text align-end"
        height="80px"
        src="img/figure/figure2.jpg"
        gradient="to right top, rgba(100,100,100,1), rgba(25,32,72,.0)"
      >
        <v-card-title> Detalle del pedido {{ order[0].voucher }} </v-card-title>
      </v-img>
      <v-card-text>
        <BasicTable
          cardTitle="Artículos"
          :loading="false"
          :propeties="propeties"
          :items="order"
        >
        </BasicTable>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import BasicTable from "../shared/BasicTableComponent.vue";

export default {
  name: "OrderDetail",
  components: {
    BasicTable,
  },
  props: {
    order: {
      type: Array,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    propeties: [
      {
        key: "name",
        title: "Artículo",
      },
      {
        key: "presentation",
        title: "Presentación",
      },
      {
        key: "amount",
        title: "Cantidad",
      },
    ],
  }),
};
</script>
