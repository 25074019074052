export function filterDirectSale(items, filter) {

  const {
    word,
    stock
  } = filter;

  let itemsFiltered = []

  //Filtro palabras
  if (!word || !word.length) {
    itemsFiltered = items;
  } else {
    let query = word.toLowerCase();
    if (query.indexOf(",") > 0) {
      query = query.split(",");
    }
    if (Array.isArray(query)) {
      query.forEach((q) => {
        let wordFilter = items.filter(
          (x) =>
            x.name.toLowerCase().indexOf(q) >= 0 ||
            x.laboratory.toLowerCase().indexOf(q) >= 0 ||
            x.drug.toLowerCase().indexOf(q) >= 0
        );
        itemsFiltered = [...itemsFiltered, ...wordFilter];
      });
      itemsFiltered = itemsFiltered.filter((item, pos) => {
        return itemsFiltered.indexOf(item) == pos;
      });
    } else {
      itemsFiltered = items.filter(
        (x) =>
          x.name.toLowerCase().indexOf(query) >= 0 ||
          x.laboratory.toLowerCase().indexOf(query) >= 0 ||
          x.drug.toLowerCase().indexOf(query) >= 0
      );
    }
  }

  //Stock
  if (stock) {
    itemsFiltered = itemsFiltered.filter(
      (x) =>
        x.stock > 0
    );
  }

  return itemsFiltered;
}

export function normalize(items) {
  return items.map(x => {
    let discount = parseInt(x.discount)
    return {
      id: x.id,
      name: x.name,
      drug: x.drug,
      presentation: x.presentation,
      laboratory: x.laboratory,
      discount: discount == 0 ? '-  ' : `${discount} %`,
      price: x.price,
      unitPrice: x.unitPrice,
      iva: x.iva === 0 ? 'No' : 'Si',
      stock: x.stock
    }
  })
}


export function getShoppingCartClass(stock) {
  if (stock == 0) return ''
  else if (stock > 0 && stock <= 10) return 'red'
  else if (stock > 10 && stock <= 20) return 'amber'
  else return 'teal'
}
