<template>
  <div>
    <v-card flat>
      <v-card-title class="overline justify-center">Carrito de compras</v-card-title>
      <div v-if="shoppingCart.length">
        <v-card-text class="shopping-cart-container">
          <v-list dense>
            <v-list-item v-for="item in shoppingCart" :key="item.id">
              <v-list-item-action>
                <v-text-field
                  dense
                  class="centered-input"
                  outlined
                  v-model="item.amount"
                  :hide-details="true"
                ></v-text-field>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title v-text="item.name" />
                <v-list-item-subtitle>
                  {{ item.presentation }}
                </v-list-item-subtitle>
                <v-divider />
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click="removeItemFromCart(item)"
                  ><v-icon v-text="'mdi-close'"
                /></v-btn>
                <v-list-item-action-text
                  v-text="
                    '$ ' +
                    item.price +
                    ' x ' +
                    item.amount +
                    (item.amount > 1 ? ' unidades' : ' unidad')
                  "
                ></v-list-item-action-text>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </div>
      <v-card-text v-else>
        <span> El carrito está vacío</span>
      </v-card-text>
      <v-divider />
      <v-card-text>
        <div>
          <p class="text-sm-right" v-text="'Total: $ ' + total"></p>
        </div>
      </v-card-text>
      <v-divider />
      <v-card-actions v-if="shoppingCart.length">
        <v-btn color="red" @click="cleanShoppingCart()" text>
          <v-icon>mdi-cart-off</v-icon>
          Vaciar carrito
        </v-btn>
        <v-spacer/>
        <v-btn @click="saveSC()" :loading="savingOrder" color="teal" dark rounded class="pa-5">
          <v-icon>mdi-cart</v-icon>
          Realizar pedido
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { eventBus } from "../../services/eventBus";

export default {
  name: "ShoppingCartCard",

  data: () => ({
    save: {
      status: false,
      message: "",
      color: "red",
    },
  }),

  methods: {
    ...mapActions("shoppingCart", [
      "removeItemFromCart",
      "saveOrder",
      "cleanShoppingCart",
    ]),
    ...mapActions("directSale", ["getOrders"]),

    async saveSC() {
      await this.saveOrder(this.shoppingCart).then((data) => {
        if (data.invoice) {
          this.cleanShoppingCart();
          eventBus.$emit("makeAlert", {
            message: `El pedido ha sido realizado con éxito.`,
            color: "teal",
          });
        } else {
          eventBus.$emit("makeAlert", {
            message: `Error interno`,
            color: "red",
          });
        }
      });
    },
  },
  computed: {
    ...mapGetters("shoppingCart", ["shoppingCart", "savingOrder"]),
    total: function () {
      return this.shoppingCart
        .map((x) => x.price * x.amount)
        .reduce((a, b) => a + b, 0)
        .toFixed(2);
    },
  },
};
</script>

<style scoped>
.centered-input {
  width: 3rem;
}
.centered-input >>> input {
  text-align: center;
}

.shopping-cart-container {
  max-height: 70vh;
  overflow-y: auto;
  display: block;
}
</style>

