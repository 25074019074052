<template>
  <div>
    <v-card-title>
      <slot name="filters"></slot>
      <span class="mx-2">
        {{ cardTitle }}
      </span>
      <v-chip v-if="items.length" color="teal lighten-1" dark>
        {{ items.length }}
      </v-chip>
      <v-chip v-else-if="!loading && !items.length" color="red ligthen-2">
        0
      </v-chip>
      <v-skeleton-loader v-else type="chip" />
      <v-spacer />
    </v-card-title>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th v-for="item in propeties" :key="item.key" class="text-center">
              {{ item.title }}
            </th>
            <th v-if="buttons" class="text-center">Opciones</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="!loading">
            <template v-if="items.length">
              <tr
                v-for="(row, index) in pages[currentPage - 1]"
                :key="index"
                class="text-center"
              >
                <td v-for="item in propeties" :key="item.key">
                  {{ item.prepend }} {{ row[item.key] }}
                </td>
                <td v-if="buttons">
                  <v-btn
                    v-for="(button, index) in buttons"
                    :key="index"
                    rounded
                    depressed
                    small
                    :color="'teal lighten-' + index"
                    class="my-2 mx-2"
                    :disabled="button.disabled"
                    :dark="!button.disabled"
                    @click="$emit(button.emit, { id: row.id })"
                  >
                    {{ button.name }}
                  </v-btn>
                </td>
              </tr>
            </template>
          </template>
          <template v-else>
            <tr>
              <td v-for="item in propeties" :key="item.key">
                <v-skeleton-loader type="table-cell@3" />
              </td>
              <td v-if="buttons">
                <v-skeleton-loader type="table-cell@3" />
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-simple-table>
    <div class="text-center">
      <v-pagination
        v-model="currentPage"
        :length="pages.length"
        color="teal"
        circle
        :disabled="loading || !pages"
        total-visible="5"
      ></v-pagination>
    </div>

    <template v-if="!loading && !items.length">
      <v-container>
        <v-row justify="center">
          <v-col cols="12">
            <v-alert
              color="red ligthen-2 ma-5"
              border="left"
              dark
              type="error"
              class="rounded20"
            >
              No se han encontrado resultados
            </v-alert>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>
import { paginateArray } from "../../services/utils";

export default {
  name: "BasicTable",
  props: {
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    propeties: {
      type: Array,
      required: true,
    },
    buttons: {
      type: Array,
      required: false,
    },
    cardTitle: {
      type: String,
      required: false,
      default: "Tabla",
    },
    itemsPerPage: {
      type: Number,
      required: false,
      default: 15,
    },
  },
  data: () => ({
    currentPage: 1,
  }),
  computed: {
    pages: function () {
      return paginateArray(
        this.items.filter((i) => i != null),
        this.itemsPerPage
      );
    },
  },
};
</script>
