<template>
  <div>
    <v-card flat v-if="!loading && orders.length">
      <v-card-title>
        <span class="overline"> Mis pedidos </span>
      </v-card-title>
      <v-card-text>
        <v-card
          flat
          outlined
          class="ma-1"
          v-for="item in pages[currentPage - 1]"
          :key="item.id"
          @click="toDetail(item)"
        >
          <v-card-title style="display: block" class="pa-0">
            <v-list>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title v-text="'Pedido ' + item[0].voucher" />
                  <v-list-item-subtitle v-text="item[0].date" />
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn color="teal" icon @click="toDetail(item)">
                    <v-icon>mdi-card-bulleted</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card-title>
        </v-card>
      </v-card-text>
      <div class="text-center">
        <v-pagination
          v-model="currentPage"
          :length="pages.length"
          color="teal"
          circle
          :disabled="loading || !pages"
          total-visible="5"
        ></v-pagination>
      </div>
    </v-card>
    <v-alert
      v-else-if="orders.length == 0"
      color="red ligthen-2"
      border="left"
      dark
      type="error"
      class="ma-5 rounded20"
    >
      No se han encontrado resultados
    </v-alert>
    <v-skeleton-loader v-else type="table" />

    <OrderDetail
      v-if="currentItem"
      :order="currentItem"
      :show="showDetail"
      @close="(currentItem = null), (showDetail = false)"
    />
  </div>
</template>

<script>
import { paginateArray } from "../../services/utils.js";
import OrderDetail from "./OrderDetailComponent.vue";

export default {
  props: {
    orders: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  components: {
    OrderDetail,
  },

  data: () => ({
    currentPage: 1,
    currentItem: null,
    showDetail: false,
  }),

  computed: {
    pages: function () {
      return paginateArray(this.orders, 5);
    },
  },

  methods: {
    sliceShoppingMeds(meds) {
      if (meds.length >= 2) {
        return meds.slice(0, 2);
      } else return meds;
    },
    toDetail(i) {
      this.currentItem = i;
      this.showDetail = true;
    },
  },
};
</script>

<style>
</style>
