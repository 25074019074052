<template>
  <v-card flat class="pa-10 mb-5 rounded20">
    <v-card-title class="overline"> Buscar medicamentos </v-card-title>
    <v-card-text>
      <v-text-field
        filled
        rounded
        label="Ingrese nombre, droga o laboratorio"
        v-model="filter.word"
        @input="$emit('search', filter)"
      >
        <template v-slot:prepend-inner>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on"> mdi-help-circle-outline </v-icon>
            </template>
            <h2 class="overline">
              Ingrese el nombre de uno o más <br />
              medicamentos,separado por coma y sin espacios.
            </h2>
            <span> Ej: 'medicamento,medicamento,laboratorio,droga' </span>
          </v-tooltip>
        </template></v-text-field
      >
      <v-checkbox
        rounded
        class="mt-0"
        v-model="filter.stock"
        label="Sólo artículos en stock"
        color="teal"
        :value="false"
        hide-details
        @change="$emit('search', filter)"
      ></v-checkbox>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "MedBrowser",
  data: () => ({
    filter: {
      word: null,
      stock: true,
    },
  }),
};
</script>

<style>
</style>
